@import './colors';
@import './variables';
@import './mixins';

@keyframes flip-in-y-axes {
  from {
    -webkit-transform: rotate3d(0, 1, 0, 90deg);
    transform: rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 0, -20deg);
    transform: rotate3d(0, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 0, 10deg);
    transform: rotate3d(0, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 0, -5deg);
    transform: rotate3d(0, 0, 0, -5deg);
  }
}

.section {
  position: relative;
  width: 100%;

  overflow-x: hidden;
}

.section-content {
  position: relative;
  width: 100%;

  max-width: 1440px;
  margin: 0 auto;

  padding: 0 80px;

  @include more-than-desktop {
    padding-left: 0;
    padding-right: 0;
  }

  @include mobile {
    padding: 0 20px;
  }
}

@keyframes fade-up-animation {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-down-animation {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-component,
.fade-trigger {
  opacity: 0;

  --fade-duration: 800ms;
  --fade-delay: 0ms;
}

.fade-animation-up {
  animation-name: fade-up-animation;
  animation-duration: var(--fade-duration);
  animation-delay: var(--fade-delay);
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.fade-animation-down {
  animation-name: fade-down-animation;
  animation-duration: var(--fade-duration);
  animation-delay: var(--fade-delay);
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.fade-done {
  opacity: 1;
}

.blur {
  pointer-events: none;
}

$spacers: (
  1: 4px,
  2: 8px,
  3: 16px,
  4: 24px,
  5: 32px,
  6: 40px,
  7: 48px
);

@each $scale, $size in $spacers {
  /* Set a $size margin to all sides at $breakpoint */
  .m-#{$scale} {
    margin: $size;
  }

  /* Set a $size margin on the top at $breakpoint */
  .mt-#{$scale} {
    margin-top: $size;
  }

  /* Set a $size margin on the right at $breakpoint */
  .mr-#{$scale} {
    margin-right: $size;
  }

  /* Set a $size margin on the bottom at $breakpoint */
  .mb-#{$scale} {
    margin-bottom: $size;
  }

  /* Set a $size margin on the left at $breakpoint */
  .ml-#{$scale} {
    margin-left: $size;
  }
}

.signup-btn {
  --btn-bg-color: #3F99F7;
  --btn-color: #ffffff;
  --btn-hover-brightness: 1.2;
  --btn-shadow: none;

  &.google {
    --btn-bg-color: #ffffff;
    --btn-color: rgba(0,0,0,0.54);
    --btn-hover-brightness: 0.99;
    --btn-shadow: 0 0.5px 2px 1px rgba(0,0,0,0.14);
  }

  &.facebook {
    --btn-bg-color: #0866ff;
    --btn-color: #ffffff;
    --btn-hover-brightness: 1.2;
    --btn-shadow: none;
  }

  position: relative;

  //display: flex;
  //align-items: center;
  width: 100%;

  background-color: var(--btn-bg-color);
  color:  var(--btn-color);

  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  line-height: 40px;
  font-weight: 500;

  height: 40px;
  padding: 0 8px;
  border-radius: 4px;
  border: none;

  text-align: center;

  cursor: pointer;

  text-transform: uppercase;

  box-shadow: var(--btn-shadow);

  transition: box-shadow 200ms ease-in 0ms, opacity 200ms ease-in 0ms, filter 200ms ease-in 0ms;

  &:hover {
    filter: brightness(var(--btn-hover-brightness));
  }

  &-icon {
    position: absolute;
    left: 8px;
    top: 8px;
    display: block;
    height: 24px;
    width: 24px;

    margin-right: 24px;
  }

  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
