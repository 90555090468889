@import 'src/assets/styles/common';

@mixin phone-shadow($x-offset: 0, $y-offset: 0, $opacity: 0.12) {
  box-shadow: $x-offset $y-offset 6px 0 rgba(0, 0, 0, $opacity);
}

.phone-frame {
  --phone-frame-color: #{$gray-1};
  --phone-frame-width: 10px;
  --phone-frame-radius: 26px;

  position: relative;

  z-index: 9000;

  width: 308px;
  height: 659px;
  border: var(--phone-frame-width) solid var(--phone-frame-color);
  background-color: var(--phone-frame-color);

  border-radius: var(--phone-frame-radius);

  box-shadow: 0 0 6px 0 rgba(0,0,0,0.2);

  @include beyond-desktop {
    width: 340px;
    height: 727.53px;
  }
}

.phone-frame-toggle,
.phone-frame-volume-up,
.phone-frame-volume-down,
.phone-frame-lock {
  position: absolute;

  width: 3px;
  background-color: var(--phone-frame-color);
}

.phone-frame-toggle {
  height: 23px;

  top: 60px;
  left: calc(-1 * (var(--phone-frame-width) + 3px));

  box-shadow: -2px 0 6px 0 rgba(0,0,0,0.2);
}

.phone-frame-volume-up,
.phone-frame-volume-down {
  height: 47px;

  left: calc(-1 * (var(--phone-frame-width) + 3px));

  box-shadow: -2px 0 6px 0 rgba(0,0,0,0.2);
}

.phone-frame-volume-up {
  top: 110px;
}

.phone-frame-volume-down {
  top: 177px;
}

.phone-frame-lock {
  height: 75px;

  top: 125px;
  right: calc(-1 * (var(--phone-frame-width) + 3px));

  box-shadow: 2px 0 6px 0 rgba(0,0,0,0.2);
}

.phone-frame-brow {
  position: absolute;

  height: 18px;
  width: 102px;
  top: -0.5px;
  left: 50%;
  transform: translateX(-50%);

  border-radius: 0 0 8px 8px;
  background-color: var(--phone-frame-color);

  z-index: 9999;
}
